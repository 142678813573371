<template>
  <div>
    <!--begin::Dashboard-->
    <div class="row">
      <div class="col-md-12">
        <router-link
          :to="{
            name: 'novoAgendamento',
            params: {
              idPaciente: -1,
              nomePaciente: 'none',
              flag: 0,
            },
          }"
          v-slot="{ href, navigate }"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <v-btn class="mt-2 textStyle" tile color="#1dd1a1">
              <span class="textStyle">Novo Agendamento</span>
            </v-btn>
          </a>
        </router-link>

        <v-btn
          @click="agendamentosTodos"
          class="ml-4 mt-2 textStyle"
          tile
          color="#f1c40f"
        >
          <span class="textStyle"> Todos agendamentos</span>
        </v-btn>

        <v-btn
          @click="agendamentosHoje"
          class="ml-4 mt-2 textStyle"
          tile
          color="#6293DB"
        >
          <span class="textStyle"> Agendados Hoje</span>
        </v-btn>

        <b-form-select
          @change="filtarPorEspecialidade"
          style="width: 20rem; border-radius: 0px; font-size: 1.1rem"
          class="ml-4 mt-2"
          v-model="especialidade"
          :options="options"
        ></b-form-select>

        <v-toolbar style="height: 6rem">
          <v-toolbar-title
            ><v-btn
              @click="consultarAgendamentos"
              color="#1dd1a1"
              class="mr-4 mb-2 mt-6"
            >
              <v-icon center small color="#FFF">fas fa-search</v-icon></v-btn
            ></v-toolbar-title
          >

          <v-text-field
            @keyup.enter.native="consultarAgendamentos"
            v-model="consulta"
            class="mt-14"
            label="Pesquise agendamentos por Data, CPF, especialidade e nome do paciente"
            color="green"
            filled
            solo-inverted
          ></v-text-field>
        </v-toolbar>

        <div class="row mt-6">
          <div
            style="
              display: flex;
              align-itens: center;
              justify-content: space-between;
            "
            class="col-md-12"
          >
            <b-tooltip :target="`before-target`" triggers="hover">
              Página anterior
            </b-tooltip>
            <b-tooltip :target="`next-target`" triggers="hover">
              Próxima página
            </b-tooltip>
            <div class="mt-2">
              <h3>Página {{ this.page }} de {{ this.totalPages }}</h3>
            </div>

            <div>
              <v-btn
                :disabled="this.page == 0 || this.page == 1 ? true : false"
                @click="anteriorPage"
                id="before-target"
                color="#1dd1a1"
                class="mr-1"
              >
                <v-icon center small color="#FFF"
                  >fas fa-arrow-left</v-icon
                ></v-btn
              >
              <v-btn
                :disabled="
                  this.page == this.totalPages || this.totalPages == 0
                    ? true
                    : false
                "
                @click="proximoPage"
                id="next-target"
                color="#1dd1a1"
              >
                <v-icon center small color="#FFF"
                  >fas fa-arrow-right</v-icon
                ></v-btn
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <v-simple-table fixed-header>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="">Paciente</th>
                <th class="">Especialidade</th>
                <th class="">Data</th>
                <th class="">Horário</th>
                <th class="">Tipo</th>
                <th class="">Status</th>
                <th class="">Opções</th>
              </tr>
            </thead>
            <tbody
              v-for="agendamento in tempAgendamentos"
              :key="agendamento.id"
            >
              <tr>
                <td>{{ agendamento.paciente_agendado.nome }}</td>
                <td>{{ agendamento.especialidade.descricao }}</td>
                <td>{{ agendamento.data }}</td>
                <td>{{ agendamento.horario_inicio }}</td>
                <td>{{ agendamento.tipo }}</td>
                <td v-if="agendamento.status == null">
                  <strong style="color: #e74c3c">Não Encaminhado</strong>
                </td>
                <td v-else>
                  <strong style="color: #1abc9c">Encaminhado p/ Triagem</strong>
                </td>
                <td>
                  <v-btn
                    v-b-tooltip.hover.top="'Editar agendamento'"
                    key="agendamento.id"
                    color="#1dd1a1"
                    class="mr-2 mb-2 mt-2"
                    @click="visualizarEditarAgendamento(agendamento.id)"
                  >
                    <v-icon center small color="#FFF"
                      >fas fa-edit</v-icon
                    ></v-btn
                  >

                  <v-btn
                    v-b-tooltip.hover.top="'Encaminhar p/ atendimento'"
                    key="agendamento.id"
                    v-if="agendamento.status == null"
                    color="#1dd1a1"
                    class="mr-2 mb-2 mt-2"
                    @click="
                      encaminharPaciente(
                        agendamento.id,
                        agendamento.paciente_id,
                        agendamento.especialidade_id
                      )
                    "
                  >
                    <v-icon center small color="#FFF"
                      >fas fa-arrow-right</v-icon
                    ></v-btn
                  >

                  <v-btn
                    v-else
                    disabled
                    v-b-tooltip.hover.top="'Encaminhar p/ atendimento'"
                    color="#1dd1a1"
                    class="mr-2 mb-2 mt-2"
                    @click="
                      encaminharPaciente(
                        agendamento.id,
                        agendamento.paciente_id,
                        agendamento.especialidade_id
                      )
                    "
                  >
                    <v-icon center small color="#FFF"
                      >fas fa-arrow-right</v-icon
                    ></v-btn
                  >
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <div
          v-if="tempAgendamentos.length == 0"
          class="d-flex flex-column-fluid flex-center mt-12"
        >
          <a href="#" class="text-center mb-10">
            <img src="media/novaImagens/logo.png" class="max-h-200px" alt="" />
          </a>
        </div>
      </div>
    </div>

    <!--end::Dashboard-->
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import * as ambulatorio from "../../../core/services/ambulatorioAPI.service";

export default {
  name: "PainelDeContas",
  async created() {
    try {
      const response = await ambulatorio.listAgendamentosPaged(1);
      this.totalPages = response.data.total_pages;
      this.agendamentos = response.data.agendamentos;
      this.tempAgendamentos = response.data.agendamentos;
    } catch (error) {
      console.log(error);
    }
  },
  data() {
    return {
      totalPages: 1,
      page: 1,
      agendamentos: [],
      tempAgendamentos: [],
      consulta: "",
      especialidade: null,
      options: [
        { value: null, text: "Selecione uma especialidade" },
        {
          value: "Medicina de família e comunidade",
          text: "Medicina de família e comunidade",
        },
        { value: "Neurologia", text: "Neurologia" },
        { value: "Cardiologia", text: "Cardiologia" },
        { value: "Endocrinologia", text: "Endocrinologia" },
        { value: "Pediatria", text: "Pediatria" },
        { value: "Ginecologia", text: "Ginecologia" },
        { value: "Obstetrícia", text: "Obstetrícia" },
        { value: "Reumatologia", text: "Reumatologia" },
        { value: "Dermatologia", text: "Dermatologia" },
      ],
    };
  },

  methods: {
    visualizarEditarAgendamento: function (idAgendamento) {
      this.$router.push({
        name: "editarDadosAgendamento",
        params: { id: idAgendamento },
      });
    },
    consultarAgendamentos: async function () {
      if (this.consulta == "" || this.consulta == " ") {
        const response = await ambulatorio.listAgendamentosPaged(1);
        this.agendamentos = response.data.agendamentos;
        this.tempAgendamentos = response.data.agendamentos;
      } else {
        const data = {
          filter: {
            nome: this.consulta,
            cpf: this.consulta,
            nome_social: this.consulta,
            tipo: this.consulta,
            "especialidades.descricao": this.consulta,
          },
          page: 1,
          size: 10,
        };

        const resultBusca = await ambulatorio.buscaAgendamentos(data);

        this.agendamentos = resultBusca.data.resultado;
        this.tempAgendamentos = resultBusca.data.resultado;
      }
    },
    agendamentosHoje: async function () {
      try {
        const response = await ambulatorio.listEncaminhamentosHoje();
        this.tempAgendamentos = response.data.agendamentos;
        this.page = 1;
        this.totalPages = 1;
      } catch (error) {
        console.log(error);
      }
    },

    filtarPorEspecialidade: async function () {
      try {
        const data = {
          filter: {
            nome: this.especialidade,
            cpf: this.especialidade,
            nome_social: this.especialidade,
            tipo: this.especialidade,
            "especialidades.descricao": this.especialidade,
          },
          page: 1,
          size: 10,
        };

        const response = await ambulatorio.buscaAgendamentos(data);
        this.tempAgendamentos = response.data.resultado;
        this.agendamentos = response.data.resultado;
        this.page = 1;
        this.totalPages = 1;
      } catch (error) {
        console.log(error);
      }
    },

    agendamentosTodos: async function () {
      try {
        const response = await ambulatorio.listAgendamentosPaged(1);
        this.totalPages = response.data.total_pages;
        this.agendamentos = response.data.agendamentos;
        this.tempAgendamentos = response.data.agendamentos;
      } catch (error) {
        console.log(error);
      }
    },
    encaminharPaciente: async function (
      agendamentoID,
      pacienteID,
      especialidadeID
    ) {
      try {
        const data = {
          encaminhamento: {
            paciente_id: pacienteID,
            especialidade_id: especialidadeID,
            setor_id: "2",
          },
        };

        const data2 = {
          agendamento: {
            status: "Atendido",
          },
        };

        await ambulatorio.updateAgendamento(agendamentoID, data2);

        await ambulatorio.encaminharPaciente(data);

        const response = await ambulatorio.listAgendamentosPaged(1);
        this.totalPages = response.data.total_pages;
        this.agendamentos = response.data.agendamentos;
        this.tempAgendamentos = response.data.agendamentos;

        Swal.fire({
          title: "",
          text: "Encaminhamento realizado com sucesso",
          icon: "success",
          confirmButtonColor: "green",
          heightAuto: false,
        });

        this.$router.push({ name: "listaAgendamentos" });
      } catch (error) {
        console.log(error);
        Swal.fire({
          title: "",
          text: "Erro ao realizar agendamento, verifique as informações",
          icon: "error",
          confirmButtonColor: "red",
          heightAuto: false,
        });
      }
    },
    proximoPage: async function () {
      try {
        this.page++;
        if (this.page <= this.totalPages) {
          const response = await ambulatorio.listAgendamentosPaged(this.page);
          this.agendamentos = response.data.agendamentos;
          this.tempAgendamentos = response.data.agendamentos;
        } else {
          this.page--;
        }
      } catch (error) {
        console.log(error);
      }
    },
    anteriorPage: async function () {
      try {
        this.page--;
        if (this.page >= 1) {
          const response = await ambulatorio.listAgendamentosPaged(this.page);
          this.agendamentos = response.data.agendamentos;
          this.tempAgendamentos = response.data.agendamentos;
        } else {
          this.page++;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  components: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Lista de agendamentos" }]);
  },
};
</script>

<style>
.textStyle {
  color: white;
  font-size: 1.2em;
}
</style>
